<template>

  <div class="modal fade" id="modal_users_role" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="fw-bold">Usuários Vinculados</h2>
          <p class="mt-4">(*) Ao vincular o usuário à nova regra de acesso ele será desvinculado da regra vigente</p>
          <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-roles-modal-action="close">
            <i class="ki-outline ki-cross fs-1"></i>
          </div>
        </div>
        <div class="modal-body scroll-y mx-5 my-7">
          <div id="kt_roles_view_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="table-responsive">
              <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0 dataTable no-footer"
                     id="kt_roles_view_table">
                <thead>
                <tr class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  <th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" aria-label="
														" style="width: 29.25px;">Membro
                  </th>
                  <th class="min-w-150px sorting" tabindex="0" aria-controls="kt_roles_view_table" rowspan="1"
                      colspan="1" aria-label="User: activate to sort column ascending" style="width: 348.75px;">Usuário
                  </th>
                </tr>
                </thead>
                <tbody class="fw-bold text-gray-600" v-for="(user,index) in users">
                <tr class="odd">
                  <td>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                      <input class="form-check-input" type="checkbox" v-model="user.is_member"
                      :disabled="((user.is_member) && !modifiedIndexes.includes(index)) || (user.id_user === this.userAuth.id_user)"
                      @click="toggleUser(index);">
                    </div>
                  </td>
                  <td class="d-flex align-items-center">
                    <div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                      <div class="symbol-label">
                        <img class="w-100" :src="url(user.user_photo)" v-if="user.user_photo" :alt="user.user_name" />
                        <img class="w-100" :src="require('@/assets/images/no-photo.png')" :alt="user.user_name" v-else />
                        <span v-else class="symbol-label bg-ubbi text-inverse-warning fw-bolder">
                          {{ user.user_name.charAt(0).toUpperCase() }}
                        </span>
                      </div>
                    </div>
                    <div class="d-flex flex-column">
                      <span
                         class="text-gray-800 text-hover-primary mb-1">{{ user.user_name }}</span>
                      <span>{{ user.user_email }}</span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="text-center pt-15">
            <button class="btn btn-light me-3" data-kt-roles-modal-action="cancel" data-bs-dismiss="modal">
              Fechar
            </button>
            <button class="btn btn-primary">
                <span class="indicator-label" @click="store()">
                  Salvar
                </span>
              <span class="indicator-progress">
                  Aguarde... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import str from "../../helpers/str";

export default {
  name: "ModalUsersAccessType",
  props: {
    users: null,
    accessType: null,
  },
  emits: ["onClose"],
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    const modalElement = document.getElementById('modal_users_role');
    modalElement.addEventListener('hidden.bs.modal', this.closeModal);
  },
  beforeUnmount() {
    const modalElement = document.getElementById('modal_users_role');
    modalElement.removeEventListener('hidden.bs.modal', this.closeModal);
  },
  data() {
    return {
      modifiedIndexes: [],
    }
  },
  methods: {
      url(path) {
          return str.url(path)
      },
    toggleUser(index) {
      if (!this.modifiedIndexes.includes(index)) {
        this.modifiedIndexes.push(index);
      }
    },
    closeModal() {
      this.modifiedIndexes = [];
      this.$emit('onClose');
      const modalElement = document.getElementById('modal_users_role');
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    },
    store() {
      const data = { users: this.users.filter(user => user.is_member === true) };
      http.put(`/places/${this.place.id_place}/access-types/${this.accessType.id_access_type}/members`, data).then(_ => {
        this.closeModal();
        Alert.toastSuccess('Usuários vinculados com sucesso!');
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
      });
    },
  }
}
</script>

<style scoped>

</style>