<template>
  <component v-bind:is="layout"></component>
</template>

<script>
import { nextTick } from 'vue'
import Guest from "./components/layouts/Guest";
import Professional from "./components/layouts/auth/Professional";

export default {
  name: "App",
  components: {
    'guest-layout': Guest,
    'professional-layout': Professional
  },
  computed: {
    layout () {
      return this.$route.meta.layout ?? 'guest-layout'
    }
  },
  mounted() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    this.tooltips = tooltipTriggerList.map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
  },
  beforeUnmount() {
    this.tooltips.forEach(tooltip => tooltip.dispose());
  },
  watch: {
    '$route.path': {
      handler() {
        nextTick(() => {
          KTToggle.createInstances();
          KTSticky.createInstances();
          KTMenu.createInstances();
          KTScroll.createInstances();
          KTDrawer.createInstances();
          KTScrolltop.createInstances();
          KTDialer.createInstances();
          KTImageInput.createInstances();
          KTPasswordMeter.createInstances();
          KTSwapper.createInstances();
        });
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";

.btn-outline-primary:hover {
  color: #fff;
  background-color: #a21c7c;
}

.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  background-color: #780f5a;
  border-color: #780f5a;
  color: #fff;
}

.btn-outline-primary {
  color: #780f5a;
  border-color: #780f5a;
}

.form-check-input:checked {
  background-color: #780f5a;
  border-color: #780f5a;
}

.bg-primary {
  background-color: #780f5a !important;
}

.form-control {
    border-color: #b5b5c3 !important;
}

.bg-ubbi {
  background-color: #780f5a !important;
}

.btn.btn-primary {
    background-color: #780f5a;
}

.btn-check:active+.btn.btn-primary, .btn-check:checked+.btn.btn-primary, .btn.btn-primary.active, .btn.btn-primary.show, .btn.btn-primary:active:not(.btn-active), .btn.btn-primary:focus:not(.btn-active), .btn.btn-primary:hover:not(.btn-active), .show>.btn.btn-primary {
    background-color: #440634 !important;
}

.menu-state-title-primary .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-title, .menu-state-title-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-title {
    color: #780f5a;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: #a21c7c;
}

.border-primary {
    border-color: #440634 !important;
}

.border-hover-primary:hover {
    border-color: #780f5a !important;
}

.bg-hover-light-primary:hover {
    background-color: rgba(120, 15, 90, 0.06) !important;
}

.nav-pills .nav-link.active {
  background-color: #780f5a;
}

.text-primary {
    color: #780f5a !important;
}

.nav-pills .nav-link {
  color: #6c757d;
}

@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 90%;
    }
}

.aside-menu .menu>.menu-item>.menu-link .menu-icon i {
    color: #a1a5b7;
}

.aside-menu .menu>.menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-icon i,
.aside-menu .menu>.menu-item:not(.here)>.menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon i {
    color: #ffffff;
}

.aside {
    background-color: #780f5a;
}

.aside .aside-logo {
    background-color: #780f5a;
}

@media (min-width: 992px) {
    .aside {
        width: 110px;
    }
}

@media (min-width: 992px) {
    .aside-enabled.aside-fixed.header-fixed .header {
        left: 110px;
    }
}

@media (min-width: 992px) {
    .aside-enabled.aside-fixed .wrapper {
        padding-left: 110px;
    }
}


</style>
