<template>
  <div v-if="!hasPermission('List Place Exams')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
    <Toolbar/>

    <div class="container-xxl">
      <div class="col-xl-12 mb-xl-10">
        <div class="card h-md-100">
          <div class="card-body pt-5">
            <ul class="nav nav-pills nav-pills-custom mb-3 d-flex">
              <li class="nav-item mb-3 me-3 me-lg-6" v-for="(kind) in kinds">
                <a
                    class="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden h-40px py-4 active"
                    data-bs-toggle="pill" :href="'#kt_exams_' + kind.id_kind">
                  <span class="nav-text text-gray-700 fw-bolder fs-6 lh-1">{{ kind.kind_name }}</span>
                  <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                </a>
              </li>
              <li class="nav-item mb-3 me-3 me-lg-6 ms-auto">
                <button type="button" class="btn btn-primary" @click="openModalStore()">
                  Novo Exame
                </button>
              </li>
            </ul>
            <hr>
            <div class="tab-content">

              <div v-for="(kind) in kinds" class="tab-pane fade active show" :id="'#kt_exams_' + kind.id_kind">
                <div class="col-md-12">
                  <input type="text" class="form-control" v-model="searchTerm"
                         placeholder="Pesquisar exame">
                </div>
                <div class="table-responsive">
                  <table class="table table-row-bordered align-middle gs-0 gy-4 my-0 table-hover">
                    <thead>
                    <tr class="fw-bold fs-6 text-muted">
                      <th>Nome</th>
                      <th>Ativo?</th>
                      <th>Tem preparo?</th>
                      <th>Ações</th>
                    </tr>
                    </thead>
                    <tbody :key="specialty.id_spec" v-for="specialty in filteredSpecialties(kind.specialties)">
                    <tr>
                      <td>
                         <span class="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                           {{ specialty.spec_name }}
                         </span>
                      </td>
                      <td>
                        <span class="badge badge-success" v-if="specialty.active">Sim</span>
                        <span class="badge badge-danger" v-else>Não</span>
                      </td>
                      <td>
                        {{ (specialty.exam_preparation != null || specialty.is_file) ? 'Sim' : 'Não' }}
                      </td>
                      <td>
                        <button class="btn btn-sm btn-outline btn-outline-secondary me-5" type="button"
                                title="Editar exame"
                                @click="onClickEdit(specialty)">
                          <i class="bi bi-pencil"></i>
                        </button>
                        <button class="btn btn-sm btn-outline btn-outline-secondary me-5" type="button"
                                title="Profissionais" @click="managerProfessionals(specialty)">
                          <i class="bi bi-people"></i>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalProfessionals :specialty="specialty"/>

  <div class="modal fade" tabindex="-1" id="modal_exam">
    <div class="modal-dialog modal-xl">
      <form @submit.prevent="formExam.id_spec ? updateExam() : storeExam()">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ formExam.id_spec ? 'Editar' : 'Novo' }} Exame
            </h5>

            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="fv-row mb-5">
                <div v-if="formExam?.id_place === place?.id_place" class="row">
                  <div class="col-md-6">
                    <label for="spec_name" class="required form-label">Nome</label>
                    <input type="text" class="form-control form-control-solid"
                           placeholder="Nome do exame..."
                           v-model="formExam.spec_name" id="spec_name" required>
                  </div>
                  <div class="col-md-6">
                    <label for="id_kind" class="required form-label">Tipo</label>
                    <select class="form-control form-control-solid" id="id_kind" required
                            aria-label="Selecione a área" v-model="formExam.id_kind">
                      <option :value="kind.id_kind" v-for="kind in kinds">
                        {{ kind.kind_name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-12">
                    <label for="spec_details" class="form-label">Descrição</label>
                    <textarea class="form-control form-control-solid" placeholder="Descrição do exame"
                              id="spec_details" v-model="formExam.spec_details"></textarea>
                  </div>
                  <div class="col-md-12">
                    <label for="tuss" class="required form-label">Código TUSS</label>
                    <MultiSelect v-model="formExam.tuss" :options="exams" appendTo="self"
                                 :optionLabel="(e) => e.exam_tuss_code + ' - ' + e.exam_name" id="tuss"
                                 :showToggleAll="false" :virtualScrollerOptions="{ itemSize: 44 }" display="chip"
                                 filter :filterFields="['exam_tuss_code', 'exam_name']" placeholder="Selecione"
                                 :maxSelectedLabels="4"
                                 class="form-control form-control-solid w-100 d-flex align-items-center justify-content-end">

                      <template #option="slotProps">
                        <div class="flex align-items-center">
                          <div>{{ slotProps.option.exam_tuss_code }} - {{ slotProps.option.exam_name }}</div>
                        </div>
                      </template>

                      <template #footer>
                        <div class="py-2 px-3">
                          <b>{{ formExam.tuss ? formExam.tuss.length : 0 }}</b> TUSS
                          selecionado{{ (formExam.tuss ? formExam.tuss.length : 0) > 1 ? 's' : '' }}.
                        </div>
                      </template>
                    </MultiSelect>
                  </div>

                  <div class="col-md-8">
                    <br>
                    <div class="mb-3">
                      <label class="form-check-label" for="spec_active">
                        Ativar exame?
                      </label>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" v-model="formExam.active"
                               role="switch" id="spec_active">

                        <label class="form-check-label">
                          {{ formExam.active ? 'Sim' : 'Não' }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <br>
                    <label for="ps_color" class="form-label">Cor do exame na agenda</label>
                    <input type="color" class="form-control form-control-solid" v-model="formExam.ps_color"
                           id="ps_color">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <hr>
                    <label for="exam_preparation" class="form-label">Preparo de exames</label>
                    <div class="mb-3">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" v-model="formExam.is_file"
                               @change="onTogglePreparationType()"
                               role="switch" id="upload-file-preparation">
                        <label class="form-check-label" for="upload-file-preparation">
                          Usar um arquivo de preparo
                        </label>
                      </div>
                    </div>

                    <div class="mb-3" v-if="formExam.is_file">
                      <div class="row">
                        <div class="col-md-3 col-xl-2" v-if="formExam.url_exam_preparation">
                          <div class="d-grid gap-2">
                            <label for="exam_preparation_file" class="form-label">
                            </label>
                            <a class="btn btn-primary" target="_blank" :href="formExam.url_exam_preparation">
                              Abrir preparo <i class="bi bi-box-arrow-up-right ms-2"></i>
                            </a>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <label for="exam_preparation_file" class="form-label">
                            Selecione o arquivo de prepapo:
                          </label>
                          <input class="form-control" type="file" accept="application/pdf"
                                 @change="handleFileChange($event)"
                                 placeholder="Selecione o arquivo de preparo"
                                 id="exam_preparation_file">
                          <br>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3" v-else>
                      <label for="exam_preparation" class="form-label">
                        Preparo do Exames:
                      </label>
                      <textarea class="form-control form-control-solid" v-model="formExam.exam_preparation"
                                placeholder="Escreva aqui o prepapo para esse exame."
                                id="exam_preparation" rows="10"></textarea>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">Salvar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Permission from "../../helpers/permission";
import Toolbar from "../../components/layouts/auth/Toolbar";
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Modal from "../../helpers/modal";
import Header from "../../components/layouts/auth/Header.vue";
import MultiSelect from 'primevue/multiselect';
import ModalProfessionals from "./ModalProfessionals.vue";

export default {
  name: "Exams",
  components: {Header, Toolbar, ModalProfessionals, MultiSelect},
  data() {
    return {
      kinds: [],
      exams: [],
      specialty: null,
      searchTerm: '',
      formExam: {
        id_spec: null,
        spec_name: null,
        spec_details: null,
        active: true,
        is_file: false,
        exam_preparation: null,
        url_exam_preparation: null,
        id_place: null,
        id_kind: '',
        tuss: [],
        file: null,
        ps_color: null,
      },
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  mounted() {
    this.getKinds();
    this.getExams();
  },
  methods: {
    hasPermission(permission) {
      return Permission.has(permission);
    },
    filteredSpecialties(specialties) {
      if (!this.searchTerm) {
        return specialties;
      }

      return specialties.filter(specialty =>
        specialty.spec_name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    onTogglePreparationType() {
      if (this.formExam.is_file) {
        this.formExam.exam_preparation = null;
      } else {
        this.formExam.file = null;
      }
    },
    handleFileChange(event) {
      this.formExam.file = event.target.files[0];
    },
    getKinds() {
      http.get(`/places/${this.place.id_place}/kinds/exams`, {params: {with_specialties: true}}).then((response) => {
        this.kinds = response.data;
      });
    },
    getExams() {
      http.get(`/exams`).then((response) => {
        this.exams = response.data;
      });
    },
    openModalStore() {
      this.formExam = {
        exam_preparation: null,
        id_kind: null,
        tuss: [],
        spec_name: null,
        spec_details: null,
        active: true,
        is_file: false,
        id_place: this.place.id_place,
        url_exam_preparation: null,
        ps_color: null,
      };

      if (this.kinds.length === 1) {
        this.formExam.id_kind = this.kinds[0].id_kind;
      }

      Modal.toggle('#modal_exam');
    },
    managerProfessionals(specialty) {
      this.specialty = specialty;

      setTimeout(() => Modal.toggle('#modal_professionals'), 200);
    },
    onClickEdit(specialty) {
      if (document.getElementById('exam_preparation_file'))
        document.getElementById('exam_preparation_file').value = null;

      this.formExam.file = null;
      this.formExam = {
        id_kind: specialty.id_kind,
        tuss: [],
        id_spec: specialty.id_spec,
        spec_name: specialty.spec_name,
        spec_details: specialty.spec_details,
        active: specialty.active,
        is_file: specialty.is_file,
        exam_preparation: specialty.exam_preparation,
        url_exam_preparation: specialty.url_exam_preparation,
        id_place: specialty.id_place,
        ps_color: specialty.ps_color,
      };

      this.formExam.tuss = specialty.exams.map(({place_spec_exam, ...rest}) => rest);

      Modal.toggle('#modal_exam');
    },
    updateExam() {
      const formData = new FormData();
      formData.append('_method', 'put');
      formData.append('spec_name', this.formExam.spec_name);
      formData.append('spec_details', this.formExam.spec_details);
      formData.append('active', this.formExam.active);
      formData.append('id_place', this.formExam.id_place);
      formData.append('id_kind', this.formExam.id_kind);
      formData.append('ps_color', this.formExam.ps_color);
      this.formExam.tuss.forEach(tussItem => formData.append('tuss[]', tussItem.id_exam));

      if (this.formExam.exam_preparation)
        formData.append('exam_preparation', this.formExam.exam_preparation);

      if (this.formExam.file)
        formData.append('file', this.formExam.file);

      http.post(`/places/${this.place.id_place}/kinds/${this.formExam.id_kind}/exams/${this.formExam.id_spec}`, formData)
          .then(() => {
            this.getKinds();
            Alert.success('Exame salvo com sucesso.');
            Modal.toggle('#modal_exam');
          }).catch(() => Alert.error('Erro ao salvar exame.'));
    },
    storeExam() {
      const formData = new FormData();
      formData.append('spec_name', this.formExam.spec_name);
      formData.append('spec_details', this.formExam.spec_details);
      formData.append('active', this.formExam.active);
      formData.append('id_place', this.formExam.id_place);
      formData.append('id_kind', this.formExam.id_kind);
      formData.append('ps_color', this.formExam.ps_color);
      this.formExam.tuss.forEach(tussItem => formData.append('tuss[]', tussItem.id_exam));

      if (this.formExam.exam_preparation)
        formData.append('exam_preparation', this.formExam.exam_preparation);

      if (this.formExam.file)
        formData.append('file', this.formExam.file);

      http.post(`/places/${this.place.id_place}/kinds/${this.formExam.id_kind}/exams`, formData)
          .then(() => {
            this.getKinds();
            Alert.success('Exame salvo com sucesso.');
            Modal.toggle('#modal_exam');
          }).catch(() => Alert.error('Erro ao salvar exame.'));
    },
  }
}
</script>

<style scoped>

</style>