<template>
    <div id="kt_calendar_app">
      <div class="justify-content-between d-none d-md-flex">
        <p class="text-center h6">
          {{schedulesCount}} Evento{{schedulesCount > 1 ? 's' : ''}}
        </p>
        <div class="input-group" style="width: 214px; margin-top: -20px; margin-bottom: 5px" title="Ir diretamente a uma data específica">
          <input type="text" class="form-control form-control-sm" v-model="specificDate" v-mask="'##/##/####'"
                 placeholder="DD/MM/YYYY" @keyup.enter="toToDateLocal()">
          <span class="input-group-text" @click="toToDateLocal()" style="cursor: pointer">
            <i class="bi bi-calendar-event text-primary"></i>
          </span>
        </div>
      </div>

      <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listGridPlugin from '@fullcalendar/list';
import brLocale from '@fullcalendar/core/locales/pt-br';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import http from "../../helpers/http";
import {mask} from "vue-the-mask";
import moment from "moment";

export default {
    name: "Calendar",
    components: {
        FullCalendar,
    },
    props: ['professional', 'idSpec', 'status', 'uniqueKey', 'goToDate'],
    emits: ['onEventClick', 'onDateClick'],
    directives: {
      mask
    },
    data() {
        return {
            specificDate: null,
            nextDateWithSchedule: null,
            prevDateWithSchedule: null,
            schedulesCount: 0,
        }
    },
    computed: {
        filters() {
            return {
                id_prof: null,
                id_spec: null,
                sched_status: null,
                start_date: '',
                end_date: '',
                base: 'AL',
            }
        },
        place() {
            return this.$store.state.auth.place;
        },
        calendarOptions() {
            return {
                themeSystem: 'bootstrap5',
                locale: brLocale,
                plugins: [bootstrap5Plugin, dayGridPlugin, interactionPlugin, timeGridPlugin, listGridPlugin],
                customButtons: {
                    customPrev: {
                        icon: 'chevron-left',
                        click: this.customPrev
                    },
                    customNext: {
                        icon: 'chevron-right',
                        click: this.customNext
                    }
                },
                headerToolbar: {
                    left: 'customPrev,customNext today',
                    center: 'title',
                    right: 'timeGridDay,timeGridWeek,dayGridMonth,listDay'
                },
                initialView: 'timeGridDay',
                slotMinTime: this.professional?.time_begin ?? '00:00:00',
                slotMaxTime: this.professional?.time_end ? this.endTime(this.professional?.time_end, this.professional?.interval_minutes) : '23:59:00',
                slotDuration: this.professional?.interval_minutes ? this.formatTimeFromMinutes(this.professional.interval_minutes) : '00:10:00',
                slotLabelInterval: this.professional?.interval_minutes ? this.formatTimeFromMinutes(this.professional.interval_minutes) : '00:10:00',
                slotLabelFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                },
                eventClick: this.onEventClick,
                dateClick: this.onDateClick,
                weekends: true,
                nowIndicator: true,
                firstDay: new Date().getDay(),
                allDaySlot: false,
                scrollTime: moment().format("HH:mm:ss"),
                now: new Date(),
                height: 'auto',
                events: this.loadEventsFullCalendar,
                // slotLaneClassNames: 'bg-light-dark',
                eventContent: function (arg) {
                  const color = arg.event.extendedProps.ps_color;
                  if (!color) {
                    return arg.event.title;
                  }

                  return {
                    html: `<div style="height: inherit; border-left: 8px solid ${color}; padding-left: 5px; border-radius: 2px">${arg.event.title}</div>`,
                  };
                }
            }
        }
    },
    watch: {
        professional() {
            this.refetchEvents();
        },
        idSpec() {
            this.refetchEvents();
        },
        status() {
            this.refetchEvents();
        },
        uniqueKey() {
            this.refetchEvents();
        },
        goToDate(newDate) {
            if (!newDate)
                return;

            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.gotoDate(newDate);
        },
    },
    methods: {
        toToDateLocal() {
          if (!this.specificDate)
            return;

          let calendarApi = this.$refs.fullCalendar.getApi();
          calendarApi.gotoDate(moment(this.specificDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        },
        customPrev() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            if (this.prevDateWithSchedule && ['timeGridDay', 'listDay'].includes(calendarApi.view.type)) {
                calendarApi.gotoDate(this.prevDateWithSchedule);
            } else {
                calendarApi.prev();
            }
        },
        customNext() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            if (this.nextDateWithSchedule && ['timeGridDay', 'listDay'].includes(calendarApi.view.type)) {
                calendarApi.gotoDate(this.nextDateWithSchedule);
            } else {
                calendarApi.next();
            }
        },
        onDateClick: function (info) {
            this.$emit('onDateClick', info.date);
        },
        onEventClick: function (info) {
            if (!info.event.extendedProps.id_sched)
                return;
            let scheduleClicked = info.event.extendedProps;
            this.$emit('onEventClick', scheduleClicked);
        },
        refetchEvents() {
            this.filters.id_prof = this.professional?.id_prof;
            this.filters.id_spec = this.idSpec;
            this.filters.sched_status  = this.status;

            if (this.$refs.fullCalendar) {
                this.$refs.fullCalendar.getApi().refetchEvents();
            }
        },
        filterSchedules() {
            if (!this.filters.id_prof)
                return new Promise((resolveInner) => {
                    setTimeout(resolveInner, 1);
                });

            let url = `/places/${this.place.id_place}/schedules`;
            return http.get(url, {params: this.filters}).then((response) => {
                this.getNextDateFromCalendar();
                return response;
            });
        },
        getNextDateFromCalendar() {
            if (!this.filters.id_prof)
                return;

            let url = `/places/${this.place.id_place}/schedules/next-date`;
            http.get(url, {params: this.filters}).then((r) => {
                this.nextDateWithSchedule = r.data.next_date;
                this.prevDateWithSchedule = r.data.prev_date;
            });
        },
        // getAllTimes() {
        //     return http.get(`/places/${this.place.id_place}/professionals/${this.filters.id_prof}/availabilities/free-times`, {
        //         params: {
        //             date_begin: this.filters.start_date,
        //             date_end: this.filters.end_date,
        //             id_spec: this.filters.id_spec,
        //         }
        //     });
        // },
        loadEventsFullCalendar(fetchInfo, successCallback) {
            if (!this.filters.id_prof) {
                return successCallback([]);
            }

            this.filters.start_date = fetchInfo.start;

            let calendarApi = this.$refs.fullCalendar.getApi();
            if (moment(fetchInfo.end).startOf('day').diff(moment(fetchInfo.start).startOf('day'), 'days') === 1) {
              this.filters.end_date = fetchInfo.start;
            } else {
              this.filters.end_date = fetchInfo.end;
            }

            let schedulePromisse = this.filterSchedules();
            //let timesPromisse = this.getAllTimes(calendarApi?.view.type);

            return Promise.all([schedulePromisse/*, timesPromisse*/]).then((res) => {
                let response = res[0];
                if (!response) {
                    successCallback([]);
                    return response;
                }

                let schedules = response.data.map((schedule) => {
                  let title = '';
                  let isBlocked = !schedule.id_sched;
                  if(isBlocked) {
                    title = 'BLOQUEADO'.concat(' | ').concat(schedule.patient_name);
                  } else {
                    title = title.concat(schedule.kind_exam ? 'E' : 'C').concat(' | ').concat(schedule.patient_name)
                  }

                  if (schedule.sched_online)
                    title = title.concat(' | ').concat('Online');

                  if (schedule.kind_exam)
                    title = title.concat(' | ').concat(schedule.spec_name);

                  if(schedule.paymeth_name)
                    title = title.concat(' | ').concat(schedule.paymeth_name);

                  if(schedule.insurance_name)
                      title = title.concat(' | ').concat(schedule.insurance_name);

                  let parsedDate = moment(schedule.schedule_date_time, "YYYY-MM-DDTHH:mm");
                  parsedDate.add(schedule.interval_minutes ?? this.professional?.interval_minutes, 'minutes');
                  let endDate = parsedDate.format("YYYY-MM-DDTHH:mm");

                    return {
                        title: title,
                        start: schedule.schedule_date_time,
                        end: endDate,
                        id: schedule.id_sched,
                        allDay: false,
                        backgroundColor: schedule.sched_status_color,
                        ...schedule
                    };
                });

                this.schedulesCount = schedules.length;


                /*
              const freeTimesSlots = () => {
                let freeTimes = res[1].data;

                if (calendarApi?.view.type === 'dayGridMonth') {
                  return freeTimes.map((time) => {
                    return {
                      start: moment(time.available_date, "YYYY-MM-DD").format("YYYY-MM-DDT")+'00:00:00',
                      end: moment(time.available_date, "YYYY-MM-DD").format("YYYY-MM-DDT")+'23:00:00',
                      // display: 'background',
                      // backgroundColor: '#fff',
                      // allDay: true,
                    }
                  });
                }


                return freeTimes.flatMap(item =>
                  item.availability_times.map((time) => {
                    let parsedDate = moment(time.available_datetime, "YYYY-MM-DDTHH:mm");
                    parsedDate.add(this.professional?.interval_minutes, 'minutes');
                    let endDate = parsedDate.format("YYYY-MM-DDTHH:mm");

                    return {
                      start: moment(time.available_datetime, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DDTHH:mm")+':00',
                      end: endDate+':00',
                      display: 'background',
                      // backgroundColor: '#fff',
                    }
                  })
                );
              };

              let freeTimes = freeTimesSlots();

              schedules.push(...freeTimes);
              */
              successCallback(schedules);
            });
        },
        endTime(endTime, interval) {
            let partes = endTime.split(':');

            let data = new Date();
            data.setHours(parseInt(partes[0]));
            data.setMinutes(parseInt(partes[1]));
            data.setSeconds(parseInt(partes[2]));

            data.setMinutes(data.getMinutes() + interval);

            return data.toTimeString().split(' ')[0];
        },
        formatTimeFromMinutes(minutes) {
            const totalSeconds = minutes * 60;
            const hours = Math.floor(totalSeconds / 3600);
            const mins = Math.floor((totalSeconds % 3600) / 60);
            const secs = totalSeconds % 60;

            return [
                hours.toString().padStart(2, '0'),
                mins.toString().padStart(2, '0'),
                secs.toString().padStart(2, '0')
            ].join(':');
        },
    }
}
</script>

<style>
.fc-toolbar .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(.4rem + 1px) calc(0.6rem + 1px);
}

:root {
    --bs-gray-200: #f5f8fa;
    --bs-gray-400: #c9c6c6;
}

div.fc-timegrid-slots > table > tbody > tr {
    height: 3.5em !important;
    border-bottom: 0 !important;
}

.fc-day-past {
    background-color: #F5F8FA;
}

.fc-event {
    cursor: pointer;
}

.popover {
    max-width: 600px !important;
}

.fc .fc-toolbar-title {
    font-size: 1.1em;
}

[data-pc-section="tableheaderrow"] {
  text-align: center;
}

.fc {
    --fc-today-bg-color: #FFFFFF;
    --fc-now-indicator-color: #a21c7c;
}

.fc-bg-event {
  //opacity: 1 !important;
  //border-bottom: 1px solid var(--bs-gray-400);
}

</style>